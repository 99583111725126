import ProductVideoService from '../../services/product-video.service'

export const state = {
    productVideos: [],
    selectedProductVideo: {},
    comments: [],
    videoComments: [],
};

export const mutations = {
    ADD_PRODUCT_VIDEO(state, value) {
        state.productVideos.push(value)
    },

    EDIT_PRODUCT_VIDEO(state, value) {
        let index = state.productVideos.findIndex(video => video.id == video.id)
        state.productVideos[index] = value
    },

    SET_PRODUCT_VIDEOS(state, value) {
        state.productVideos = value
    },

    SET_PRODUCT_VIDEO(state, value) {
        state.selectedProductVideo = value
    },

    DELETE_PRODUCT_VIDEO(state, value) {
        let index = state.productVideos.findIndex((res) => res.id == value)
        state.productVideos.splice(index, 1)
    },

    LIKE_VIDEO(state, value) {
        let index = state.productVideos.findIndex((res) => res.id == value.videoId)
        state.productVideos[index].userHasLiked = value.userHasLiked
        if(value.userHasLiked) {
            state.productVideos[index].totalLikes = state.productVideos[index].totalLikes + 1 
        }
        else {
            state.productVideos[index].totalLikes = state.productVideos[index].totalLikes - 1
        }
    },

    ADD_VIDEO_COMMENT(state, payload) {
        if(payload.commentId) {
            let index = state.videoComments.findIndex(comment => comment.id == payload.commentId)
            state.videoComments[index].replies = payload.result.replies || []
        }
        else {
            if(!payload.result.replies) { 
                payload.result.replies = []
            }
           state.videoComments.unshift(payload.result) 
        }
        const videoIndex = state.productVideos.findIndex(video => video.id == payload.videoId)
        state.posts[videoIndex].totalComments = state.productVideos[videoIndex].totalComments + 1
    },

    UPDATE_VIDEO_COMMENT(state, payload) {
        let index = state.videoComments.findIndex(comment => comment.id == payload.commentId)
        state.videoComments[index] = payload.result 
    },

    FIND_VIDEO_COMMENTS(state, value) {
        state.videoComments = value
    },

    DELETE_VIDEO_COMMENT(state, value) {
        let index = state.videoComments.findIndex((res) => res.id == value)
        state.videoComments.splice(index, 1)
    },
};

export const actions = {
    
    async addProductVideo({commit}, payload) {
        try {
            let response = await ProductVideoService.createProductVideo(payload)
            commit('ADD_PRODUCT_VIDEO', response[0])
            return response
        }
        catch(error) {
            //console.log('create product video action', error)
        }
    },
    
    async editProductVideo({commit}, payload) {
        try {
            let response = await ProductVideoService.updateProductVideo(payload)
            commit('EDIT_PRODUCT_VIDEO', response[0])
            return response
        }
        catch(error) {
            //console.log('update product video action', error)
        }
    },

    async getAllProductVideos({ commit }) {
        try {
            let response = await ProductVideoService.findAllProductVideos()
            commit('SET_PRODUCT_VIDEOS', response)
            return response
        }
        catch(error) {
            console.log('find all product videos action', error)
            throw error
        }
    },

    async findProductVideosByBusinessId({ commit }, payload) {
        try {
            const { businessId, userId } = payload
            let response = await ProductVideoService.findProductVideosByBusinessId(businessId, userId)
            commit('SET_PRODUCT_VIDEOS', response)
            return response
        }
        catch(error) {
            console.log('find all product videos action', error)
            throw error
        }
    },

    async getOneProductVideo({ commit }, payload) {
        try {
            let response = await ProductVideoService.findOneProductVideo(payload.productId, payload.ProductVideoId)
            commit('SET_PRODUCT_VIDEO', response)
            return response
        }
        catch(error) {
            //console.log('find one product video action', error)
        }
    },

    async removeProductVideo({commit}, payload) {
        try {
            let response = await ProductVideoService.deleteProductVideo(payload.productId, payload.ProductVideoId)
            commit('DELETE_PRODUCT_VIDEO', id)
            return response
        }
        catch(error) {
            //console.log('delete product video action', error)
        }
    },

    async generateQRCodeImage({commit}, payload) {
        try {
            let response = await ProductVideoService.generateQRCodeImage(payload)
            return response
        }
        catch(error) {
            //console.log('error generating qr code action', error)
        }
    },

    async downloadProductVideo({commit}, payload) {
        try {
            let response = await ProductVideoService.downloadProductVideo(payload)
            return response
        }
        catch(error) {
            //console.log('error downloading product video action', error)
        }
    },

    async deleteVideoFile({commit}, payload) {
        try {
            let response = await ProductVideoService.deleteVideoFile(payload)
            return response
        }
        catch(error) {
            //console.log('error deleting video file action', error)
        }
    },

    async likeVideo({commit}, payload) {
        try {
            let response = await ProductVideoService.likeVideo(payload)
            //console.log('likeVideo', response)
            const props = {
                videoId: payload.videoId,
                userHasLiked: response
            }
            commit('LIKE_VIDEO', props)
            return response
        }
        catch(error) {
            //console.log('update video action', error)
        }
    },

    async createVideoComment({commit}, payload) {
        try {
            let response = await ProductVideoService.createVideoComment(payload)
            payload.result = response[0]
            commit('ADD_VIDEO_COMMENT', payload)
            return response
        }
        catch(error) {
            //console.log('create video comment action', error)
        }
    },

    async updateVideoComment({commit}, payload) {
        try {
            let response = await ProductVideoService.updateVideoComment(payload)
            //console.log('updateVideoComment', response)
            payload.result = response[0]
            commit('UPDATE_VIDEO_COMMENT', payload)
            return response
        }
        catch(error) {
            //console.log('update video comment action', error)
        }
    },

    async findAllVideoComments({ commit }, videoId) {
        try {
            let response = await ProductVideoService.findAllVideoComments(videoId)
            commit('FIND_VIDEO_COMMENTS', response)
            return response
        }
        catch(error) {
            //console.log('find all video comments action', error)
        }
    },

    async deleteVideoComment({commit}, payload) {
        try {
            const { videoId, commentId } = payload
            let response = await ProductVideoService.deleteVideoComment(videoId, commentId)
            commit('DELETE_VIDEO_COMMENT', commentId)
            return response
        }
        catch(error) {
            //console.log('delete post comment action', error)
        }
    },
};

export const getters = {
    
};
